import React, { FormEvent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'Api';
import { TextField, DialogActions, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

const CertificationCreation: React.FC = () => {

  const history = useHistory();

  const form = useRef<HTMLFormElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [open, setOpen] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElement = form.current || new HTMLFormElement();
    const body = new FormData(formElement);
    body.append('image', files[0]);
    Api.post('certification', body).then(
      () => {
        setOpen(false);
        history.go(0);
      }
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}> + Neues Zertifikat </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Neues Zertifikat erstellen</DialogTitle>
        <form ref={form} onSubmit={handleSubmit}>
          <DialogContent>
            <TextField id="certification" name="certification" variant="outlined" label="Certificate name" fullWidth margin="normal" required />
            <DropzoneArea filesLimit={1} acceptedFiles={['image/*']} onChange={setFiles} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" fullWidth type="submit">Erstellen</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

};

export default CertificationCreation;
