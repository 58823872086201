import React, { useEffect, useState } from 'react';
import EvaluationCard from 'components/Evaluation/EvaluationCard';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import Api from 'Api';
import { useHistory } from 'react-router-dom';
import { IEvaluation } from 'components/Models';
import EvaluationCreation from 'components/Evaluation/EvaluationCreation';

const EvaluationCards: React.FC = () => {
  const [certifications, setCertifications] = useState<IEvaluation[]>();

  useEffect(() => {
    Api.get('evaluation').then((res) => {
      setCertifications(res.data.evaluations);
    })
  }, [])

  const history = useHistory();

  return (
    <>
      <Typography variant="h4">
        <Box component="span" lineHeight={2}>
          Evaluationen
        </Box>
        <Box component="span" px={2}><EvaluationCreation></EvaluationCreation></Box>
      </Typography>
      <Grid container spacing={5}>
        {
          certifications 
          ? certifications.map((evaluation: IEvaluation) => (
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <EvaluationCard
                evaluation={evaluation}
              />
            </Grid>
          ))
          : <p>Keine Einträge</p>
        }
      </Grid>
    </>
  )
};

export default EvaluationCards;
