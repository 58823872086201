import React from 'react';
import { Card, CardContent, Typography, CardActionArea, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IEvaluation } from 'components/Models';

export interface ICertificationProps {
  evaluation: IEvaluation,
}

const CertificationCard: React.FC<ICertificationProps> = ({evaluation}) => {
  const history = useHistory();
  
  const goToCertification = () => history.push('/evaluation/' + evaluation.id);
  const getCertificationImage = () => {
    if(evaluation.certification && evaluation.certification.image_path){return '/storage/' + evaluation.certification.image_path;};
    return '/placeholder.png'
  }

  const onMediaFallback = (event: any) => event.target.src = '/placeholder.png';
  
  if(evaluation){
    return (
      <Card>
        <CardActionArea onClick={goToCertification}>
          <CardContent>
            <Typography variant="h5" component="h2">{evaluation.evaluation}</Typography>
          </CardContent>
          <CardMedia component="img" image={getCertificationImage()} onError={onMediaFallback} height="400"/>
        </CardActionArea>
      </Card>
    );
  } else {
    return <p>empty evaluation</p>;
  }
};

export default CertificationCard;
