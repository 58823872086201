import React, { useState } from 'react';
import { IEvaluationCriteria } from 'components/Models';
import { ListItem, ListItemText, Select, MenuItem } from '@material-ui/core';
import Api from 'Api';

interface ICertificationCriteriaListItemProps{
  criteria: IEvaluationCriteria,
}

const EvaluationCriteriaListItem: React.FC<ICertificationCriteriaListItemProps> = ({criteria}) => {
  
  const [criteriaStatus, setCriteriaStatus] = useState<number>(criteria.criteria_status_id);

  const changeStatus = (event: any) => {
    setCriteriaStatus(event.target.value);
    const body = {
      'criteria_status_id': event.target.value
    };
    Api.put('evaluation/criteria/' + criteria.id, body).then((res) => {
      console.log(res.data.criteria.criteria_status_id);
      //criteria.criteria_status_id = res.data.criteria.criteria_status_id;
    });
  };

  return(
  <>
    <ListItem  button component="a" href={"/evaluation/criteria/"+criteria.id}>
      <ListItemText>{criteria.certification_criteria.criteria}</ListItemText>
        <Select
          id="criteria_status_id"
          value={criteriaStatus}
          onChange={changeStatus}
        >
          <MenuItem value={1}>Nicht begonnen</MenuItem>
          <MenuItem value={2}>In Progress</MenuItem>
          <MenuItem value={3}>Abgeschlossen</MenuItem>
        </Select>
    </ListItem>
  </>
  );
};

export default EvaluationCriteriaListItem;
