import React, { useContext } from 'react';
import { Toolbar, AppBar, Button, withStyles, Menu, MenuItem, ListItemIcon, makeStyles, createStyles, Theme } from '@material-ui/core';
import logo from 'eduart.svg';
import Api from 'Api';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'App';
import HomeIcon from '@material-ui/icons/Home';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filler: {
      flexGrow: 1,
    },
  }),
);

const EduArtBar: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const [auth, setAuth] = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.clear();
    setAuth({
      isLoggedIn: false
    })
    Api.get('logout').finally(() => history.push('/'));
  }

  const StyledButton = withStyles({
    root: {
      color: 'white',
    },
  })(Button);

  return ( 
    <AppBar position="static">
      <Toolbar>
        <img src={logo} className="App-logo" alt="logo" />
        <AuthContext.Consumer>
          {([auth, setAuth]) =>  {
            if(auth.isLoggedIn){
              return(
                <>
                  <StyledButton variant="text" startIcon={<HomeIcon />} href="/">Dashboard</StyledButton>
                  <StyledButton variant="text" startIcon={<CardMembershipIcon />} href="/certifications">Zertifikate</StyledButton>
                  <StyledButton variant="text" startIcon={<PlaylistAddCheckIcon />} href="/evaluations">Evaluationen</StyledButton>
                  <div className={classes.filler}></div>
                  <div>
                  <StyledButton variant="text" startIcon={<AccountCircleIcon />} aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu}>{auth.name_full}</StyledButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem disableRipple={true} disabled={true}>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      {auth.name_full}
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      Ausloggen
                    </MenuItem>
                  </Menu></div>
                </>
              );
            }
          }}
        </AuthContext.Consumer>
      </Toolbar>
    </AppBar>
  );
}

export default EduArtBar;
