import React from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import Api from 'Api';
import { ICertification } from 'components/Models';

export interface ICertificationProps {
  certification: ICertification
}

const CertificationListItem: React.FC<ICertificationProps> = ({certification}) => {
  
  const history = useHistory();

  const goToCertificationEdit = () => history.push('/certification/' + certification.id);
  const deleteCertification = () => Api.delete('/certification/' + certification.id).then(() => history.go(0));
  
  return (
    <TableRow key={certification.id}>
      <TableCell component="th" scope="row">
        {certification.certification}
      </TableCell>
      <TableCell align="right">
        <IconButton aria-label="edit" onClick={goToCertificationEdit}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={deleteCertification}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CertificationListItem;
