import React, { FormEvent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'Api';
import { TextField, DialogActions, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { ICertification } from 'components/Models';

const UserCreation: React.FC = () => {

  const history = useHistory();

  const form = useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElement = form.current || new HTMLFormElement();
    const body = new FormData(formElement);
    Api.post('user', body).then(
      () => {
        setOpen(false);
        history.go(0);
      }
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}> + Neuer Benutzer </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Benutzer erstellen</DialogTitle>
        <form ref={form} onSubmit={handleSubmit}>
          <DialogContent>
            <TextField id="email" name="email" variant="outlined" label="E-Mail" fullWidth margin="normal" required />
            <TextField id="name_first" name="name_first" variant="outlined" label="Name" fullWidth margin="normal" required />
            <TextField id="name_last" name="name_last" variant="outlined" label="Lastname" fullWidth margin="normal" required />
            <TextField id="password" name="password" variant="outlined" label="Password" fullWidth margin="normal" required />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" fullWidth type="submit">Erstellen</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

};

export default UserCreation;
