import React, { useState, useEffect } from 'react';
import Api from 'Api';
import LoadingBuffer from 'components/General/LoadingBuffer';


const ProfilePage: React.FC = () => {

  const [profile, setProfile] = useState<any>()

  useEffect(() => {
    Api.get('profile/1').then(res => {
      setProfile(res.data);
      console.log(res);
    })
  }, [])

  return (
    <div>
      {
        profile === undefined
        ? <>
            <LoadingBuffer></LoadingBuffer>
            <p>Loading</p>
          </>
        : <>
            <p>{profile.email}</p>
            <p>{profile.name_full}</p>
          </>
      }
    </div>
  )
}

export default ProfilePage;
