import React, { FormEvent, useRef, useContext } from 'react';
import { Card, CardContent, TextField, Button, CardActions, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Api from 'Api';
import { AuthContext } from 'App';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  }
})

const LoginForm: React.FC = () => {

const classes = useStyles();
const form = useRef<HTMLFormElement>(null);
const history = useHistory();
const [auth, setAuth] = useContext(AuthContext);

const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  const formElement = form.current || new HTMLFormElement();
  const body = new FormData(formElement);

  Api.post('login', body).then(
    (res) => {
      setAuth({
        isLoggedIn: true,
        name_full: res.data.user.name_full
      });
      localStorage.setItem('token', res.data.access_token);
      localStorage.setItem('username', res.data.user.name_full);
      Api.defaults.headers['Authorization'] = 'Bearer ' + res.data.access_token;
      history.push('/certifications');
    }
  );
}

return (
    <Card className={classes.root}>
      <form ref={form} onSubmit={handleSubmit}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Login
          </Typography>
          <TextField id="email" name="email" variant="outlined" label="E-Mail" fullWidth margin="normal" required />
          <TextField id="password" name="password" variant="outlined" label="password" fullWidth  margin="normal" type="password" required />
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" fullWidth type="submit">Login</Button>
        </CardActions>
      </form>
    </Card>
  )
};

export default LoginForm;
