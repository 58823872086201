import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Api from 'Api';
import { TextField, DialogActions, Button, Dialog, DialogTitle, DialogContent, Select, MenuItem } from '@material-ui/core';
import { IEvaluation } from 'components/Models';

const EvaluationCreation: React.FC = () => {

  const history = useHistory();

  const form = useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState(false);


  const [certification, setCertification] = useState<string>('');
  useEffect(() => {
    Api.get('certification').then((res) => {
      setCertifications(res.data.certifications);
    });
  }, []);

  const [certifications, setCertifications] = useState();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCertification(event.target.value as string);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElement = form.current || new HTMLFormElement();
    const body = new FormData(formElement);
    body.append("certification_id", certification);
    Api.post('evaluation', body).then(
      () => {
        setOpen(false);
        history.go(0);
      }
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}> + Neue Evaluation </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Neue Evaluation erstellen</DialogTitle>
        <form ref={form} onSubmit={handleSubmit}>
          <DialogContent>
            <TextField id="evaluation" name="evaluation" variant="outlined" label="Evaluation name" fullWidth margin="normal" required />
            <Select
              id="certification_category_id"
              onChange={handleChange}
            >
              {
                certifications 
                ? certifications.map((certification: IEvaluation) => (
                  <MenuItem value={certification.id}>{certification.certification}</MenuItem>
                ))
                : <p>Keine Einträge</p>
              }
            </Select>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" fullWidth type="submit">Erstellen</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

};

export default EvaluationCreation;
