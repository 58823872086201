import React, { useEffect, useState } from 'react';
import { Typography, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@material-ui/core';
import Api from 'Api';
import { useHistory } from 'react-router-dom';
import UserListItem from 'components/User/UserListItem';
import UserCreation from './UserCreation';

export interface IUser {
  id: number,
  email: string,
  name_full: string,
  locked: boolean,
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>();

  useEffect(() => {
    Api.get('user').then((res) => {
      setUsers(res.data.users);
    })
  }, [])

  const history = useHistory();
  const goToUserCreation = () => history.push('/user/create');

  return (
    <>
      <Typography variant="h4">
        <Box component="span" lineHeight={2}>
          Benutzer
        </Box>
        <Box component="span" px={2}><UserCreation></UserCreation></Box>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>E-Mail</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            users 
            ? users.map((user: IUser) => (
              <UserListItem
                user={user}
              />
            ))
            : <p>Keine Einträge</p>
          }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
};

export default UserList;
