import React, { useState } from 'react';
import './App.css';
import useScript from './useScript';
import { BrowserRouter, Route } from 'react-router-dom';
import ProfilePage from './components/User/ProfilePage';
import PublicPage from './components/Auth/PublicPage';
import EduArtBar from './components/General/EduArtBar';
import { Box, Container } from '@material-ui/core';
import EvaluationCards from './components/Evaluation/EvaluationCards';
import EvaluationDetails from './components/Evaluation/EvaluationDetails';
import EvaluationCreation from './components/Evaluation/EvaluationCreation';
import PrivatePage from './components/Auth/PrivatePage';
import UserList from './components/User/UserList';
import CertificationList from 'components/Certification/CertificationList';
import CertificationDetails from 'components/Certification/CertificationDetails';
import EvaluationCriteriaDetails from './components/Evaluation/EvaluationCriteriaDetails';
import CertificationCriteriaDetails from 'components/Certification/CertificationCriteriaDetails';


var auth: AuthContextData = {
  isLoggedIn: false
};

if(localStorage.getItem('token') != null){
  auth.isLoggedIn = true;
}
let username = localStorage.getItem('username');
if(username != undefined){
  auth.name_full = username;
}

export const AuthContext = React.createContext<[AuthContextData, React.Dispatch<React.SetStateAction<AuthContextData>>]>([auth, () => {}]);

interface AuthContextData {
  name_full?: string,
  isLoggedIn: boolean
}

function App() {
  const [state, setState] = useState(auth);
  useScript('/hosted/tinymce/tinymce.min.js');

  return (
    <AuthContext.Provider value={[state, setState]}>
      <Box className="App" display="flex" flexDirection="column">
        <BrowserRouter>
          <EduArtBar />
          <Box height={1} my={5}>
            <Routes/>
          </Box>
        </BrowserRouter>
      </Box>
    </AuthContext.Provider>
  );
}

function Routes(){
  if(auth.isLoggedIn){
    return <PrivateRoutes/>;
  } else {
    return <PublicRoutes/>;
  }
}

function PrivateRoutes(){
  return (<Container>
          <Route exact path="/" component={PrivatePage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/evaluations" component={EvaluationCards} />
          <Route exact path="/evaluation/:id" component={EvaluationDetails} />
          <Route exact path="/evaluation/criteria/:id" component={EvaluationCriteriaDetails} />
          <Route exact path="/certifications" component={CertificationList} />
          <Route exact path="/certification/:id" component={CertificationDetails} />
          <Route exact path="/certification/criteria/:id" component={CertificationCriteriaDetails} />
          <Route exact path="/users" component={UserList} />
        </Container>);
}

function PublicRoutes(){
  return (<Route exact path="/" component={PublicPage}/>);
}

export default App;
