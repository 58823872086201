import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import LoginForm from 'components/Auth/LoginForm';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

const PublicPage: React.FC = () => {
return (
  <Box height={1} display="flex" justifyContent="center" alignItems="center">
    <LoginForm />
  </Box>
  )
};

export default PublicPage;
