import React from 'react';
import { List } from '@material-ui/core';
import CertificationCriteriaListItem from '../Certification/CertificationCriteriaListItem';
import { ICertificationCriteria } from 'components/Models';

interface ICertificationCriteriaListProps{
  criterias?: ICertificationCriteria[],
}
const CertificationCriteriaList: React.FC<ICertificationCriteriaListProps> = ({criterias}) => {

  return(
  <>
    <List component="div" disablePadding>
          {
            criterias
          ? criterias!.map((criteria: any) => {
                return (
                  <CertificationCriteriaListItem criteria={criteria}/>
                );
            })
          : <p>Keine Einträge</p>
          }
        </List>
  </>
  );
};

export default CertificationCriteriaList;
