import React from 'react';
import CertificationCriteriaList from 'components/Certification/CertificationCriteriaList';
import {
  List,
  ListItem,
  ListItemText,
  Collapse, Typography
} from '@material-ui/core';
import { ICertificationCategory } from 'components/Models';

export interface ICertificationCategoryListProps {
  categories: ICertificationCategory[];
}

const CertificationCategoryList: React.FC<ICertificationCategoryListProps> = ({ categories }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <>
      <List>
          {categories.map(
            (category: ICertificationCategory) => {
              return (
              <>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h6" component="h6">
                      <Typography variant="caption">{category.custom_category_number} </Typography>
                      {category.category}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <CertificationCriteriaList criterias={category.certification_criteria}></CertificationCriteriaList>
                </Collapse>
                </>
              );
            }
          )}
      </List>
    </>
  );
};

export default CertificationCategoryList;
