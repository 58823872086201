import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from 'Api';
import { Box, Typography, Fab, makeStyles, createStyles, Theme } from '@material-ui/core';
import { ICertificationCriteriaField } from 'components/Models';
import GoBackButton from 'components/General/GoBackButton';
import LoadingBuffer from 'components/General/LoadingBuffer';
import FieldCreation from 'components/Certification/FieldCreation';
import CertificationCriteriaItem from './CertificationCriteriaItem';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      zIndex: 100,
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const CertificationCriteriaDetails: React.FC = () => {

  const classes = useStyles();
  const {id} = useParams();

  const [criteria, setCriteria] = useState();

  useEffect(() => {
    Api.get('certification/criteria/' + id).then((res) => {
      setCriteria(res.data);
    });
  }, []);

  const saveCriteria = () => {
    Api.put('certification/criteria/' + id, criteria);
  };


  return(
<>
      {
        criteria
        ? 
        <Box m={2}>
          
          <Typography variant="h4">
            <GoBackButton></GoBackButton>
            {criteria.criteria}
            <FieldCreation></FieldCreation>
          </Typography>
          {
            criteria.certification_criteria_field
            ?
            criteria.certification_criteria_field.map((field: ICertificationCriteriaField)=>{
              return <CertificationCriteriaItem field={field}></CertificationCriteriaItem>
            })
            : <p>Keine Einträge</p>
          }
          <Fab variant="extended" color="primary" className={classes.fab} onClick={saveCriteria}>
            <SaveIcon/> Speichern
          </Fab>
        </Box>
        : <LoadingBuffer></LoadingBuffer>
      }
    </>
  )

};

export default CertificationCriteriaDetails;
