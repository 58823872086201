import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Api from 'Api';
import { Box, Typography } from '@material-ui/core';
import CertificationCategoryList from 'components/Certification/CertificationCategoryList';
import GoBackButton from 'components/General/GoBackButton';
import LoadingBuffer from 'components/General/LoadingBuffer';
import CategoryCreation from 'components/Certification/CategoryCreation';
import CriteriaCreation from './CriteriaCreation';

const CertificationDetails: React.FC = () => {
  const { id } = useParams();

  const history = useHistory();

  const [certification, setCertification] = useState();

  useEffect(() => {
    Api.get('certification/' + id).then((res) => {
      setCertification(res.data);
    });
  }, []);

  const goToCriteriaCreator = () => history.push('/certification/' + id + '/create');

  return (
    <>
      {certification ? (
        <Box m={2}>
          <Typography variant="h4">
            <GoBackButton/>
            {certification.certification}
            <CriteriaCreation></CriteriaCreation>
            <CategoryCreation/>
          </Typography>
          <CertificationCategoryList categories={certification.certification_category}></CertificationCategoryList>
        </Box>
      ) : (
        <LoadingBuffer></LoadingBuffer>
      )}
    </>
  );
};

export default CertificationDetails;
