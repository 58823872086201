import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from 'Api';
import { Box, Typography, Fab, makeStyles, createStyles, Theme } from '@material-ui/core';
import EvaluationCriteriaItem from 'components/Evaluation/EvaluationCriteriaItem';
import { IEvaluationCriteriaField } from 'components/Models';
import GoBackButton from 'components/General/GoBackButton';
import LoadingBuffer from 'components/General/LoadingBuffer';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      zIndex: 100,
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const EvaluationCriteriaDetails: React.FC = () => {

  const classes = useStyles();

  const {id} = useParams();

  const [criteria, setCriteria] = useState();

  useEffect(() => {
    Api.get('evaluation/criteria/' + id).then((res) => {
      setCriteria(res.data);
    });
  }, []);

  const saveCriteria = () => {
    Api.put('evaluation/criteria/' + id, criteria);
  };


  return(
<>
      {
        criteria
        ? 
        <Box m={2}>
          
          <Typography variant="h4">
            <GoBackButton></GoBackButton>
            {criteria.certification_criteria.criteria}
          </Typography>
          {
            criteria.evaluation_criteria_field
            ?
            criteria.evaluation_criteria_field.map((field: IEvaluationCriteriaField)=>{
              return <EvaluationCriteriaItem field={field}></EvaluationCriteriaItem>
            })
            : <p>Keine Einträge</p>
          }
          <Fab variant="extended" color="primary" className={classes.fab} onClick={saveCriteria}>
            <SaveIcon/> Speichern
          </Fab>
        </Box>
        : <LoadingBuffer></LoadingBuffer>
      }
    </>
  )

};

export default EvaluationCriteriaDetails;
