import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Api from 'Api';
import { Box, Button, Typography } from '@material-ui/core';
import EvaluationCriteriaList from 'components/Evaluation/EvaluationCriteriaList';
import GoBackButton from 'components/General/GoBackButton';
import LoadingBuffer from 'components/General/LoadingBuffer';

const EvaluationDetails: React.FC = () => {
  const { id } = useParams();

  const history = useHistory();

  const [evaluation, setEvaluation] = useState();

  useEffect(() => {
    Api.get('evaluation/' + id).then((res) => {
      setEvaluation(res.data);
    });
  }, []);

  const goToCriteriaCreator = () => history.push('/evaluation/' + id + '/create');

  return (
    <>
      {evaluation ? (
        <Box m={2}>
          <Typography variant="h4">
            <GoBackButton/>
            {evaluation.certification.certification}
          </Typography>
          <EvaluationCriteriaList criterias={evaluation.evaluation_criteria}></EvaluationCriteriaList>
        </Box>
      ) : (
        <LoadingBuffer></LoadingBuffer>
      )}
    </>
  );
};

export default EvaluationDetails;
