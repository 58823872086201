import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Api from 'Api';
import { TextField, DialogActions, Button, Dialog, DialogTitle, DialogContent, Select, MenuItem } from '@material-ui/core';
import { ICertificationCategory } from 'components/Models';

const CriteriaCreation: React.FC = () => {

  const { id } = useParams();
  const history = useHistory();

  const form = useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState(false);

  const [categories, setCategories] = useState();
  useEffect(() => {
    Api.get('certification/' + id).then((res) => {
      setCategories(res.data.certification_category);
    });
  }, []);

  const [category, setCategory] = useState<string>('');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElement = form.current || new HTMLFormElement();
    const body = new FormData(formElement);
    body.append("certification_id", id);
    body.append("certification_category_id", category);
    Api.post('certification/criteria', body).then(
      () => {
        setOpen(false);
        history.go(0);
      }
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}> + Neues Kriterium </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Neues Kriterium erstellen</DialogTitle>
        <form ref={form} onSubmit={handleSubmit}>
          <DialogContent>
            <TextField id="criteria" name="criteria" variant="outlined" label="Criteria name" fullWidth margin="normal" required />
            <Select
              id="certification_category_id"
              onChange={handleChange}
            >
{
                categories 
                ? categories.map((category: ICertificationCategory) => (
                  <MenuItem value={category.id}>{category.category}</MenuItem>
                ))
                : <p>Keine Einträge</p>
              }
            </Select>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" fullWidth type="submit">Erstellen</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

};

export default CriteriaCreation;
