import React from 'react';
import { IEvaluationCriteria } from 'components/Models';
import { List } from '@material-ui/core';
import EvaluationCriteriaListItem from './EvaluationCriteriaListItem';

interface ICertificationCriteriaListProps{
  criterias?: IEvaluationCriteria[],
}
const EvaluationCriteriaList: React.FC<ICertificationCriteriaListProps> = ({criterias}) => {

  return(
  <>
    <List component="div" disablePadding>
          {
            criterias
          ? criterias!.map((criteria: any) => {
                return (
                  <EvaluationCriteriaListItem criteria={criteria}/>
                );
            })
          : <p>Keine Einträge</p>
          }
        </List>
  </>
  );
};

export default EvaluationCriteriaList;
