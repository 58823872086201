import React from 'react';
import { Typography } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { ICertificationCriteriaField } from 'components/Models';

interface ICertificationCriteriaItemProps {
  field: ICertificationCriteriaField,
}

const CertificationCriteriaItem: React.FC<ICertificationCriteriaItemProps> = ({field}) => (
  <div>
    <Typography variant="h6">{field.field}</Typography>
    <Editor
         initialValue={field.content}
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent link | removeformat | help'
         }}
         onEditorChange={(content) => {field.content = content}}
       />
  </div>
);

export default CertificationCriteriaItem;
