import React from 'react';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';


const GoBackButton: React.FC = () => {

  const history = useHistory();
  
  const goBack = () => history.goBack();

  return(
    <IconButton onClick={goBack}>
      <ChevronLeftIcon />
    </IconButton>
  )
};

export default GoBackButton;
