import React, { useEffect, useState } from 'react';
import { Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@material-ui/core';
import Api from 'Api';
import { useHistory } from 'react-router-dom';
import CertificationListItem from 'components/Certification/CertificationListItem';
import CertificationCreation from 'components/Certification/CertificationCreation';
import { ICertification } from 'components/Models';

const CertificationList: React.FC = () => {
  const [certifications, setCertifications] = useState<ICertification[]>();


  useEffect(() => {
    Api.get('certification').then((res) => {
      setCertifications(res.data.certifications);
    })
  }, [])

  const history = useHistory();

  return (
    <>
      <Typography variant="h4">
        <Box component="span" lineHeight={2}>
          Zertifikate
        </Box>
        <Box component="span" px={2}>
          <CertificationCreation></CertificationCreation>
        </Box>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Zertifikat</TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            certifications 
            ? certifications.map((certification: ICertification) => (
              <CertificationListItem
                certification={certification}
              />
            ))
            : <p>Keine Einträge</p>
          }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
};

export default CertificationList;
