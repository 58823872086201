import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { IEvaluationCriteria } from 'components/Models';

interface ICertificationCriteriaListItemProps{
  criteria: IEvaluationCriteria,
}

const CertificationCriteriaListItem: React.FC<ICertificationCriteriaListItemProps> = ({criteria}) => {

  return(
  <>
    <ListItem  button component="a" href={"/certification/criteria/"+criteria.id}>
      <ListItemText>{criteria.criteria}</ListItemText>
    </ListItem>
  </>
  );
};

export default CertificationCriteriaListItem;
