import React from 'react';
import { IEvaluationCriteriaField } from 'components/Models';
import { Typography, Grid } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

interface ICertificationCriteriaItemProps {
  field: IEvaluationCriteriaField,
}

const EvaluationCriteriaItem: React.FC<ICertificationCriteriaItemProps> = ({field}) => (
  <div>
    <Typography variant="h6">{field.certification_criteria_field.field}</Typography>
    <Grid container spacing={3}>
        <Grid item xs={6}>
          <div
          dangerouslySetInnerHTML={{
            __html: field.certification_criteria_field.content
          }}></div>
        </Grid>
        <Grid item xs={6}>
          <Editor
            initialValue={field.content}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
            }}
            onEditorChange={(content) => {field.content = content}}
          />
        </Grid>
      </Grid>
  </div>
);

export default EvaluationCriteriaItem;
