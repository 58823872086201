import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';


const LoadingBuffer: React.FC = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <CircularProgress></CircularProgress>
  </Box>
);

export default LoadingBuffer;
