import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Card, CardContent, CardActions, Button, Paper, Grid } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PeopleIcon from '@material-ui/icons/People';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import Api from 'Api';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    height: '100%',
  }
})

const PrivatePage: React.FC = () => {

const classes = useStyles();

const [evaluation, setEvaluation] = useState();

useEffect(() => {
  Api.get('evaluation/last').then((res) => {
    setEvaluation(res.data);
  });
}, []);

function getEvaluationCard() {
  if(evaluation){
    return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Zuletzt verwendet
        </Typography>
        <Typography variant="h5" component="h2">
          {evaluation.evaluation}
        </Typography>
        <Typography color="textSecondary">
          {evaluation.certification.certification}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href={"/evaluation/"+evaluation.id}>Zur Evaluation</Button>
      </CardActions>
    </Card>
    );
  } else {
    return <p>Noch keine Evaluation erstellt</p>
  }
}

return (
  <Grid container spacing={3}>

      <Grid item xs={12}>
        <Paper>
          <Box height="100px" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h4" component="h4">Welcome back!</Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>

        <Grid container spacing={3}>
          <Grid item xs={3}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="contained" className={classes.button} fullWidth size="large" href="/certifications" startIcon={<CardMembershipIcon />}>Zertifikate</Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" className={classes.button} fullWidth size="large" href="/evaluations" startIcon={<PlaylistAddCheckIcon />}>Evaluationen</Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" className={classes.button} fullWidth size="large" href="/users" startIcon={<PeopleIcon />}>Benutzer</Button>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={9}>
            {getEvaluationCard()}
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  )
};

export default PrivatePage;
