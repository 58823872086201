import React from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useHistory } from 'react-router-dom';
import { IUser } from 'components/User/UserList';
import Api from 'Api';

export interface IUserProps {
  user: IUser
}

const UserListItem: React.FC<IUserProps> = ({user}) => {
  
  const history = useHistory();

  const goToUserEdit = () => history.push('/user/' + user.id);
  const deleteUser = () => Api.delete('/user/' + user.id).then(() => history.go(0));

  const lockUser = () => Api.put('/user/' + user.id + '/lock').then(() => history.go(0));
  const unlockUser = () => Api.put('/user/' + user.id + '/unlock').then(() => history.go(0));
  
  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell>{user.name_full}</TableCell>
      <TableCell align="right">
        <IconButton aria-label="edit" onClick={goToUserEdit}>
          <EditIcon />
        </IconButton>
        <UserState locked={user.locked}/>
        <IconButton aria-label="delete" onClick={deleteUser}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  function UserState(props: any){
    if(props.locked){
      return <LockedButton/>;
    } else {
      return <UnlockedButton/>;
    }
  }
  
  function LockedButton() {
    return (<IconButton aria-label="lock" onClick={unlockUser}>
            <LockIcon />
          </IconButton>)
  }
  
  function UnlockedButton() {
    return (<IconButton aria-label="unlock" onClick={lockUser}>
            <LockOpenIcon />
          </IconButton>)
  }
};

export default UserListItem;
